<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Coupons</h1>
          <p>
            <router-link to="/admin/coupons/new" class="button button-ci button-round">
              <img src="../../../assets/icons/plus.svg" />
              <span>Coupon hinzufügen</span>
            </router-link>
          </p>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>Code</th>
                <th>Betrag</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="coupon in coupons" v-bind:key="coupon.uuid">
                <td width="10%"></td>
                <td width="40%">{{ coupon.coupon_code }}</td>
                <td width="40%">{{ coupon.coupon_amount }}</td>
                <td width="10%" style="text-align: right"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      coupons: [],
    };
  },
  methods: {
    get_data() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/coupons", {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.coupons = response.data.coupons;
        });
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss">
.pending-wrap {
  border: 1px solid #444;
  margin-bottom: 20px;

  h3 {
    width: 100%;
    padding: 14px;
    margin: 0;
    background: #444;
    color: #fff;
  }
}
</style>
